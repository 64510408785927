import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TestimonialsSection from "../Testimonials";
import config from "../../config";
import Cookies from "js-cookie";
import "animate.css"; // Import Animate.css for animations

function Shop() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [cart, setCart] = useState([]);
  const productRefs = useRef([]);
  const [reviews, setReviews] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [sortOption, setSortOption] = useState("none"); // State for sorting option

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/fetchproducts`);
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/fetchcategories`);
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setCategories(["All", ...data.map((cat) => cat.category_name)]);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchReviews = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/fetchreview/${productId}`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setReviews(data);  // Set the reviews for the product
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchReviews();
  }, []);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.getAttribute("data-index"), 10);
          setVisibleProducts((prevVisibleProducts) => [
            ...prevVisibleProducts,
            index,
          ]);
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    productRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      productRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [products]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const filteredProducts = products.filter((product) => {
    const matchesCategory =
      selectedCategory === "All" || product.category === selectedCategory;
    const matchesSearch = product.productName
      .toLowerCase()
      .includes(searchQuery);
    return matchesCategory && matchesSearch;
  });

  const sortedProducts = filteredProducts.slice().sort((a, b) => {
    switch (sortOption) {
      case "price-asc":
        return a.price - b.price;
      case "price-desc":
        return b.price - a.price;
      case "sell":
        return b.lastProductSell - a.lastProductSell;
      default:
        return 0;
    }
  });

  const productview = (productId) => {
    // alert("Product View");
    navigate(`/singleproduct/${productId}`);
  };

  const addToCart = (product) => {
    const updatedCart = [...cart, product];
    setCart(updatedCart);
    Cookies.set("cart", JSON.stringify(updatedCart));
    alert(`${product.productName} added to cart`);
  };

  const truncateDescription = (description, wordLimit) => {
    return (
      description.split(" ").slice(0, wordLimit).join(" ") +
      (description.split(" ").length > wordLimit ? "..." : "")
    );
  };

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="bg-gray-50 py-12">
      {/* Categories Section */}
      <div className="container mx-auto px-4 mb-12">
        <h2 className="text-3xl font-semibold text-center mb-6 text-green-600 animate__animated animate__fadeIn animate__delay-1s">
          Explore Our Multiple Categories
        </h2>
        <div className="flex flex-wrap justify-center gap-4 mb-8">
          {categories.map((category, index) => (
            <div
              key={index}
              className={`relative p-6 border rounded-lg shadow-lg bg-white transition-transform transform hover:scale-105 cursor-pointer
                            animate__animated animate__pulse animate__infinite`}
              onClick={() => handleCategoryChange(category)}
            >
              <div className="absolute top-0 left-0 mt-2 ml-2 text-3xl font-bold text-green-600 animate__animated animate__bounceIn">
                {category[0]}
              </div>
              <span className="ml-8 text-lg font-semibold">{category}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Main Shop Section */}
      <div className="container mx-auto px-4 flex">
        {/* Filter Sidebar */}
        <div
          className={`w-1/4 pl-2 pt-1 pr-4 bg-green-100 border border-green-300 rounded-lg shadow-md ${
            showFilter ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300`}
        >
          <h2 className="text-2xl font-semibold mb-4 text-green-600 animate__animated animate__fadeIn">
            Filter by
          </h2>

          {/* Category Filter */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Category</h3>
            <div className="space-y-2">
              {categories.map((category, index) => (
                <div
                  key={index}
                  onClick={() => handleCategoryChange(category)}
                  className={`p-3 rounded-lg cursor-pointer transition-colors duration-300 ease-in-out
                                    ${
                                      selectedCategory === category
                                        ? "bg-green-500 text-white"
                                        : "bg-white text-green-700 hover:bg-green-50"
                                    }
                                    border border-green-300 shadow-sm`}
                >
                  {category}
                </div>
              ))}
            </div>
          </div>

          {/* Sorting Filter */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Sort by</h3>
            <div className="relative">
              <select
                value={sortOption}
                onChange={handleSortChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-md bg-white appearance-none focus:outline-none focus:ring-2 focus:ring-green-500 transition-transform duration-300 ease-in-out"
              >
                <option value="none">None</option>
                <option value="price-asc">Price Low to High</option>
                <option value="price-desc">Price High to Low</option>
                <option value="sell">Product Sell-wise</option>
              </select>
              <svg
                className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Products Grid */}
        <div className="w-3/4">
          <h1 className="text-4xl font-bold text-center mb-8 text-green-600 animate__animated animate__fadeIn">
            Shop
          </h1>
          <p className="text-center text-lg text-gray-500 mb-12">
            The herbal choice is a healthy choice.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 pl-6">
            {sortedProducts.length > 0 ? (
              sortedProducts.map((product, index) => (
                <div
                  key={product.id}
                  data-index={index}
                  ref={(el) => (productRefs.current[index] = el)}
                  className={`flex flex-col items-center bg-white p-5 rounded-lg shadow-lg transition-transform duration-1000 ease-out 
                                    ${
                                      visibleProducts.includes(index)
                                        ? "transform translate-x-0 opacity-100"
                                        : "transform translate-x-20 opacity-0"
                                    }
                                    hover:scale-105 hover:shadow-2xl hover:border-gray-500 cursor-pointer`}
                  style={{ transitionDelay: `${index * 100}ms` }}
                  onClick={() => productview(product.id)}
                >
                  {product.images && product.images.length > 0 ? (
                    <img
                      src={`${config.API_BASE_URL}/images/${
                        JSON.parse(product.images)[0]
                      }`}
                      alt={product.productName}
                      className="w-32 h-32 mb-4 rounded-lg object-cover"
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                  <span className="text-center text-gray-800 font-semibold">
                    {product.productName}
                  </span>
                  <p className="text-center text-gray-600 mb-2">
                    {truncateDescription(product.description, 15)}
                  </p>
                  <div className="flex items-center mb-2">
                    <p className="text-red-600 text-xl line-through mr-2">
                      ₹ {product.overprice}/-
                    </p>
                    <p className="text-green-600 text-xl">
                      ₹ {product.price}/-
                    </p>
                  </div>
                  <button
                    onClick={() => productview(product.id)}
                    className="mt-2 mx-2 bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
                  >
                    View Product
                  </button>
                </div>
              ))
            ) : (
              <p className="text-center text-lg text-gray-600 w-full">
                No products available
              </p>
            )}
          </div>
        </div>
      </div>

      <TestimonialsSection />
    </div>
  );
}

export default Shop;
