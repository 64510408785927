// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer';
import ContactPage from './Components/Header/Contact';
import RegisterPage from './Components/Header/Register';
import HomePage from './Components/HomePage';
import Shop from './Components/Header/Shop';
import SingleProduct from './Components/Header/SingleProduct';
import SocialMedia from './Components/SocialMedia';
import CheckOut from './Components/CheckOut';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy';
import Blog from './Components/Header/Blog';
import Aboutus from './Components/Header/Aboutus';
import TestimonialsVideos from './Components/Header/TestimonialsVideos';
import './App.css';
import Login from './Components/Login';
import { AuthProvider } from './AuthContext';
import TermsConditions from './Components/Footer/TermsConditions';
import FAQ from './Components/Footer/FAQ';
import { initGA, logPageView } from './googleAnalytics';
import MetaPixel from './Components/MetaPixel';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  

  useEffect(() => {
    // Initialize Google Analytics on app load
    initGA();
  }, []);

  useEffect(() => {
    // Track pageviews for Meta Pixel when the route changes
    if (window.fbq) {
      window.fbq('track', 'PageView');  // Track page view with Meta Pixel
    }
  }, [location]); // Trigger when the location changes

  useEffect(() => {
    // Track pageviews for Google Analytics when the location changes
    const path = location.pathname;
    logPageView(path);
  }, [location]);

  return (
    <>
        {/* Inject Meta Pixel component */}
      <SocialMedia />
      <Header />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/create-account" element={<RegisterPage />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/singleproduct/:productId' element={<SingleProduct />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/Aboutus' element={<Aboutus />} />
        <Route path='/TestimonialsVideos' element={<TestimonialsVideos />} />
        <Route path='/TermsConditions' element={<TermsConditions />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/FAQ' element={<FAQ />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
